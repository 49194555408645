.boutButtonContainer {
    flex-grow: 1;
    flex-basis: 0;
}

.boutButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 850px) {
    .boutButtonText {
        display: none;
    }
}

.cardsListContainer {
    position: absolute;
    bottom: min(20vh, 150px);
    height: 15vh;
    width: 50%;
    display: flex;
}

.boutCard {
    width: 15vh;
    height: 15vh;
    line-height: 15vh;
    text-align: center;
    font-size: 15vh;
}